import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function useSessionCheck() {
    const navigate = useNavigate(); // Get the navigation function

    useEffect(() => {
        // Function to check the session
        async function checkSession() {
            try {
                const response = await fetch("/check-session", { credentials: "include" }); // Include credentials for cookies

                if (response.status === 401) {
                    navigate("/login"); // Redirect to login page if unauthorized
                }
            } catch (error) {
                console.error("Error checking session:", error);
                navigate("/login"); // Redirect on error as a fallback
            }
        }

        checkSession(); // Run the session check when the component mounts
    }, [navigate]); // Dependencies array
}

export default useSessionCheck;
