import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import useSessionCheck from "../hooks/useSessionCheck"; // Adjust the relative path based on your project structure


const CreateOrganization = () => {
  // Check if top-level before calling useSessionCheck

  useSessionCheck();
  const [name, setName] = useState('');
  // const [phone, setPhone] = useState('');
  const [admin, setAdmin] = useState('');

  const organization = null;
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!name) {
      alert("All fields must be filled");
      return;
    }

    const newOrganization = { name,admin };
    try {
      const response = await fetch('/organization', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newOrganization),
        credentials: 'include',
      });

      if (response.ok) {
      //  const data = await response.json();
      //  setOrganization(data);
        alert("Successfully created organization");

        // Reset form fields
        setName('');
        setAdmin('');

        // Redirect to another route (e.g., '/communication-channels')
        navigate('/communication-channels');
      } else {
        const errorData = await response.json();
        alert(`Error creating organization: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error sending request:', error);
      alert('Error creating organization. Try again later.');
    }
  };

  return (
    <div style={{ maxWidth: '400px', margin: '0 auto', padding: '20px', fontFamily: 'Arial' }}>
      <h2>Create organization</h2>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="name" style={{ display: 'block', marginBottom: '5px' }}>Organization Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Organization Name"
            style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }}
          />
        </div>

        {/* <div style={{ marginBottom: '10px' }}> */}
          {/* <label htmlFor="phone" style={{ display: 'block', marginBottom: '5px' }}>Telefone:</label> */}
          {/* <input */}
            {/* // type="tel" */}
            {/* // id="phone" */}
            {/* // value={phone} */}
            {/* // onChange={(e) => setPhone(e.target.value)} */}
            {/* // placeholder="Digite seu telefone" */}
            {/* // style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }} */}
          {/* // /> */}
        {/* </div> */}

        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="admin" style={{ display: 'block', marginBottom: '5px' }}>Organization owner:</label>
          <input
            type="admin"
            id="admin"
            value={admin}
            onChange={(e) => setAdmin(e.target.value)}
            placeholder="Organization owner"
            style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }}
          />
        </div>
        <button
          type="submit"
          style={{
            backgroundColor: '#000',
            color: 'white',
            padding: '10px',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            width: '100%',
          }}
        >
          Criar Organização
        </button>
      </form>

      {organization && (
        <div style={{ marginTop: '20px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}>
          <h3>Organization created</h3>
          <p><strong>Name:</strong> {organization.name}</p>
          {/* <p><strong>Telefone:</strong> {organization.phone}</p> */}
          <p><strong>Organization owner:</strong> {organization.admin}</p>
        </div>
      )}
    </div>
  );
};

export default CreateOrganization;
