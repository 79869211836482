import React, { useState, useEffect } from "react";
import useSessionCheck from "../hooks/useSessionCheck"; // Adjust the relative path based on your project structure

const connectedBases = async () => {
  //get the connected bases
  try {
    const response = await fetch(`/connected-bases`, {
      method: "GET",
      credentials: "include",
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to get connected bases:", response.statusText);
      return {drive: false, dropbox: false}; // Default placeholder
    }
  }
  catch (error) {
    console.error("Error fetching connected bases:", error);
    return {drive: false, dropbox: false}; // Default placeholder
  }
}


const KnowledgeBases = () => {
  useSessionCheck(); // Check the session when the component mounts
  const [connectedData, setConnectedData] = useState(null);

  useEffect(() => {
    const fetchConnectedBases = async () => {
      const data = await connectedBases();
      setConnectedData(data);
    };

    fetchConnectedBases();
  }, []);

  const knowledgeBases = connectedData
    ? [
    {
      title: "Google Drive",
      description: "Connect to Google Drive to load documents into knowledge base.",
      status: connectedData.drive ? "Connected" : "Available",
      icon: "/Logo-Drive.png", // Caminho ajustado
      link: "/auth/drive", // Caminho ajustado
    },
    {
      title: "Dropbox",
      description: "Connect to Dropbox to load documents into knowledge base.",
      status: connectedData.dropbox ? "Connected" : "Available",
      icon: "/Logo-Dropbox.png",
      link: "/auth/dropbox",

    },
    {
      title: "Agenda",
      description: "Connect to Calendar apps to load events into knowledge base.",
      status: "Coming Soon",
      icon: "/Logo-Agenda.png",
    },
    {
      title: "Local Files",
      description: "Upload local files to load data into knowledge base.",
      status: "Coming Soon",
      icon: "/Logo-Database.png",
    },
    {
      title: "Website",
      description: "Load data from web pages into knowledge base.",
      status: "Coming Soon",
      icon: "/Logo-Website.png",
    },
    {
      title: "Proprietary Database",
      description: "Setup the knowledge base and load data manually.",
      status: "Coming Soon",
      icon: "/Logo-Database.png",
    },
    {
      title: "CRM",
      description: "Connect CRM software to load data into knowledge base.",
      status: "Coming Soon",
      icon: "/Logo-Salesforce.png",
    },
    {
      title: "Email",
      description: "Connect to email provider to load data into knowledge base.",
      status: "Coming Soon",
      icon: "/Logo-Email.png",
    },
  ] : [];

  const colors = [
    "#fff",
    "#fff",
    "#fff",
    "#fff",
    "#fff",
    "#fff",
    "#fff",
    "#fff",
  ];

  const defaultIcon = "/public/"; // Substitua pelo caminho do ícone padrão

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#F5F5F5",
        minHeight: "100vh",
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        position: "relative",
      }}
    >
      <h1 style={{ marginBottom: "30px", textAlign: "center" }}>Knowledge Bases</h1>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "20px",
          width: "80%",
        }}
      >
        {knowledgeBases.map((base, index) => (
          <div
            key={index}
            style={{
              backgroundColor: colors[index % colors.length],
              padding: "20px",
              borderRadius: "8px",
              border: "1px solid #fff",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
              color: "#333",
            }}
          >
            <img
              src={base.icon}
              alt={`${base.title} icon`}
              onError={(e) => (e.target.src = defaultIcon)}
              style={{
                width: "50px",
                height: "50px",
                marginBottom: "10px",
                borderRadius: "4px",
              }}
            />
            <h2 style={{ fontSize: "18px", margin: "0 0 10px 0", textAlign: "center" }}>
              {base.title}
            </h2>
            <p
              style={{
                fontSize: "14px",
                flex: "1",
                marginBottom: "10px",
                textAlign: "center",
              }}
            >
              {base.description}
            </p>
            <button
              disabled={base.status === "Coming Soon" || base.status === "Connected"}
              onClick={() => {
                if (base.status === "Available" && base.link) {
                  window.location.href = base.link;
                }
              }}
              style={{
                backgroundColor:
                  base.status === "Coming Soon"
                    ? "#444"
                    : base.status === "Connected"
                    ? "#28a745"
                    : "#22598f",
                color: "#fff",
                padding: "10px",
                border: "none",
                borderRadius: "4px",
                cursor:
                  (base.status === "Coming Soon" || base.status === "Connected")
                    ? "not-allowed"
                    : "pointer",
                transition: "background-color 0.3s",
              }}
            >
              {base.status === "Coming Soon"
                ? "Coming Soon"
                : base.status === "Connected"
                ? "Connected"
                : "Connect"}
            </button>
          </div>
        ))}
      </div>
      <button
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          backgroundColor: "#28a745",
          color: "#fff",
          padding: "10px 20px",
          border: "none",
          borderRadius: "8px",
          fontSize: "16px",
          cursor: "pointer",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
        onClick={() => (window.location.href = "/register-contacts")}
      >
        Continue
      </button>
    </div>
  );
};

export default KnowledgeBases;