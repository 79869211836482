import React, { useState } from "react";
import useSessionCheck from "../hooks/useSessionCheck"; // Adjust the relative path based on your project structure


const CommunicationChannels = () => {
  useSessionCheck(); // Check the session when the component mounts
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [isConnected, setIsConnected] = useState(false);

  const channelComunication = [
    {
      title: "Whatsapp",
      description: "Connect your WhatsApp to start communication.",
      status: "Available",
      icon: "/Logo-Wpp.png",
    },
    {
      title: "Instagram",
      description: "Connect your Instagram to start communication.",
      status: "Available",
      icon: "/Logo-Instagram.png",
    },
    {
      title: "Agenda",
      description: "Connect your calendar to start communication.",
      status: "Coming Soon",
      icon: "/Logo-Agenda.png",
    },
    {
      title: "Email",
      description: "Connect your email to start communication.",
      status: "Coming Soon",
      icon: "/Logo-Email.png",
    },
  ];

  const handleConnect = async () => {
    if (whatsappNumber.trim() !== "") {
      //send a request to register whatsapp
      try {
      const response = await fetch('/demo/whatsapp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({phone_number: whatsappNumber }),
        credentials: 'include',
      });

      if (response.ok) {
        alert("Successfully connected to WhatsApp");
        setIsConnected(true);
      } else {
        const errorData = await response.json();
        alert(`Error connecting to WhatsApp: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error sending request:', error);
      alert('Error connecting to WhatsApp. Try again later.');
    }

    } else {
      alert("Please enter your WhatsApp number.");
    }
  };

  const handleEdit = () => {
    setIsConnected(false);
    setWhatsappNumber(""); // Optionally clear the number for re-entry
  };

  const colors = ["#fff", "#fff", "#fff", "#fff"];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#F5F5F5",
        minHeight: "100vh",
        padding: "20px",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <h1 style={{ marginBottom: "30px", textAlign: "center" }}>
        Communication Channels
      </h1>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "20px",
          width: "80%",
        }}
      >
        {channelComunication.map((base, index) => (
          <div
            key={index}
            style={{
              backgroundColor: colors[index % colors.length],
              padding: "20px",
              borderRadius: "8px",
              border: "1px solid #fff",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "250px",
              color: "#333",
            }}
          >
            <img
              src={base.icon}
              alt={`${base.title} icon`}
              style={{
                width: "50px",
                height: "50px",
                marginBottom: "10px",
                borderRadius: "4px",
              }}
            />
            <h2 style={{ fontSize: "18px", marginBottom: "10px" }}>
              {base.title}
            </h2>
            <p
              style={{
                fontSize: "14px",
                flex: "1",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              {base.description}
            </p>
            {base.title === "Whatsapp" && (
              <div style={{ width: "100%", textAlign: "center" }}>
                {isConnected ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <strong
                        style={{
                          color: "#28a745",
                          fontSize: "16px",
                          flex: 1,
                          textAlign: "center",
                        }}
                      >
                        {whatsappNumber}
                      </strong>
                      <button
                        onClick={handleEdit}
                        style={{
                          backgroundColor: "transparent",
                          color: "red",
                          border: "none",
                          fontSize: "16px",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        title="Edit Number"
                      >
                        ✖
                      </button>
                    </div>
                  </>
                ) : (
                  <input
                    type="text"
                    placeholder="Enter your WhatsApp number"
                    value={whatsappNumber}
                    onChange={(e) => setWhatsappNumber(e.target.value)}
                    style={{
                      padding: "8px",
                      width: "90%",
                      marginBottom: "10px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    }}
                  />
                )}
                <button
                  onClick={handleConnect}
                  disabled={isConnected}
                  style={{
                    backgroundColor: isConnected ? "#28a745" : "#22598f",
                    color: "#fff",
                    padding: "10px",
                    border: "none",
                    borderRadius: "4px",
                    cursor: isConnected ? "not-allowed" : "pointer",
                    transition: "background-color 0.3s",
                  }}
                >
                  {isConnected ? "Connected" : "Connect"}
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
      <button
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          backgroundColor: "#28a745",
          color: "#fff",
          padding: "10px 20px",
          border: "none",
          borderRadius: "8px",
          fontSize: "16px",
          cursor: "pointer",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
        onClick={() => window.location.href = "/knowledge-bases"}
      >
        Continue
      </button>
    </div>
  );
};

export default CommunicationChannels;
