import React, { useEffect, useState } from "react";

const IndexFiles = () => {
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  useEffect(() => {
    let intervalId;

    const fetchData = async () => {
      try {
        const response = await fetch("/index", {
            method: "GET",
            credentials: "include",
            });

        if (response.status === 503) {
          console.warn("Resource unavailable");
          setIsButtonEnabled(false); // Disable button
          return false;
        }

        if (response.ok) {
          setIsButtonEnabled(true); // Enable button
          return true;
        }

        throw new Error(`Unexpected status: ${response.status} - ${response.statusText}`);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsButtonEnabled(false); // Disable button on error
        throw error;
      }
    };

    const handlePeriodicFetch = async () => {
            await fetchData();
    };

    // Fetch immediately and then every 30 seconds
    handlePeriodicFetch();
    intervalId = setInterval(handlePeriodicFetch, 3000);

    return () => {
      // Cleanup the interval when the component unmounts
      clearInterval(intervalId);
    };
  }, []);

  const handleButtonClick = () => {
    console.log(isButtonEnabled ? "Button clicked" : "Button disabled");
    setIsButtonEnabled(false);
    //make a post request to the server
    fetch("/index", {
      method: "POST",
      credentials: "include",
    })
  };

  return (
    <div>
      <h1>Index files</h1>
        <p>This may take a while...</p>
      <button onClick={handleButtonClick} disabled={!isButtonEnabled}       style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: isButtonEnabled ? "#007bff" : "#ccc",
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        position: "relative",
        cursor: isButtonEnabled ? "pointer" : "not-allowed",
      }}>
        {isButtonEnabled ? "Index" : "Indexing..."}
      </button>
    </div>
  );
};

export default IndexFiles;
