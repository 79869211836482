import React from 'react';
import { Outlet } from 'react-router-dom'; // This will render nested routes
import SidebarMenu from '../pages/SidebarMenu'; // Sidebar is included in this layout

const DashboardLayout = () => {
  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <SidebarMenu /> {/* Sidebar stays visible */}
      <div style={{ flexGrow: 1, padding: '20px' }}>
        <Outlet /> {/* Nested route content will be rendered here */}
      </div>
    </div>
  );
};

export default DashboardLayout;