import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import "./SidebarMenu.css";
import useSessionCheck from "../hooks/useSessionCheck";

const getUserData = async () => {
  try {
    const response = await fetch(`/user-data`, {
      method: "GET",
      credentials: "include",
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to get user data:", response.statusText);
      return { 
        name: "User", 
        photo_url: "https://img.freepik.com/free-vector/blue-circle-with-white-user_78370-4707.jpg", 
      }; // Default placeholder
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    return { 
      name: "User", 
      photo_url: "https://img.freepik.com/free-vector/blue-circle-with-white-user_78370-4707.jpg", 
    }; // Default placeholder
  }
};

const SidebarMenu = () => {
  useSessionCheck();
  const [photoUrl, setPhotoUrl] = useState("https://via.placeholder.com/100");
  const [userName, setUserName] = useState("user");

  useEffect(() => {
    const fetchPhoto = async () => {
      const data = await getUserData();
      const url = data.photo_url;
      const name = data.name;
      
      setPhotoUrl(url);
      setUserName(name);
    };
    fetchPhoto();
  }, [userName]);

  return (
    <div className="container">
      <aside className="sidebar">
        <div className="profile-section">
          <img
            src={photoUrl}
            alt="User Profile"
            className="profile-pic"
            width="50"
            height="50"
            onError={(e) => { e.target.src = "https://img.freepik.com/free-vector/blue-circle-with-white-user_78370-4707.jpg"; }}
          />
          <p className="greeting">Olá, {userName}!</p>
        </div>
        <ul>
          <li>
            <Link to="/dashboard" className="no-underline-white">
              Index Files
            </Link>
          </li>
  <li>
    <Link to="/dashboard/create-organization" className="no-underline-white">
      Organization
    </Link>
  </li>
  <li>
    <Link to="/dashboard/knowledge-bases" className="no-underline-white">
      Knowledge Bases
    </Link>
  </li>
  <li>
    <Link to="/dashboard/communication-channels" className="no-underline-white">
      Communication Channels
    </Link>
  </li>
  <li>
    <Link to="/dashboard/register-contacts" className="no-underline-white">
      Contacts
    </Link>
  </li>
</ul>
      </aside>
      <main className="content">
        {/* The content will be displayed here based on the nested route */}
      </main>
    </div>
  );
};

export default SidebarMenu;
