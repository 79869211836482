import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GoogleStyledButton from './pages/GoogleStyledButton';
import CreateOrganization from './pages/CreateOrganization';
import KnowledgeBases from './pages/KnowledgeBases';
import ContactAdder from './pages/ContactAdder';
import CommunicationChannels from './pages/ChannelComunications';
import DashboardLayout from './components/DashboardLayout';
import IndexFiles from './pages/IndexFiles';


// Component for redirecting
function Frontpage() {
  return (
    <iframe
      src="/frontpage/index.html"
      title="Standalone Frontpage"
      style={{ width: "100%", height: "100vh", border: "none", backgroundColor: "#ffffff" }}
    ></iframe>
  );
}

const App = () => {
  return (
    <div
      style={{
        backgroundColor: '#F5F5F5',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        margin: 0,
        fontFamily: 'Arial, sans-serif',
      }}
    >
      {/* Nome da empresa no centro */}
      {/* Router com as rotas */}
      <Router>
        <Routes>
          <Route path="/" element={<GoogleStyledButton />} />
          <Route path="/login" element={<GoogleStyledButton />} />
          <Route path="/create-organization" element={<CreateOrganization/>} />
          <Route path="/knowledge-bases" element={<KnowledgeBases/>} />
          <Route path="/register-contacts" element={<ContactAdder/>} />
          <Route path="/communication-channels" element={<CommunicationChannels/>} />
            {/* Dashboard Route with Sidebar */}
          <Route path="/dashboard" element={<DashboardLayout />}>
            {/* Nested routes for the dashboard content */}
            <Route path="" element={<IndexFiles />} />
            <Route path="create-organization" element={<CreateOrganization />} />
            <Route path="knowledge-bases" element={<KnowledgeBases />} />
            <Route path="register-contacts" element={<ContactAdder />} />
            <Route path="communication-channels" element={<CommunicationChannels />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
};

export default App;
