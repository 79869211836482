import React from 'react';
import googleIcon from '../assets/images/google_icon.png'; // Adjust the path to your image file

const GoogleStyledButton = () => {

  const handleClick = () => {
   //make a get request to /auth/sso/google
   window.location.href = '/sso/google'; 
  };

  return (
    <div>
      <h1 style={{ marginBottom: '20px' }}>Tachyon Labs</h1>
      <button
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#ffffff',
          border: '1px solid #dadce0',
          borderRadius: '4px',
          color: '#3c4043',
          fontFamily: 'Roboto, Arial, sans-serif',
          fontSize: '14px',
          fontWeight: 500,
          padding: '10px 16px',
          cursor: 'pointer',
          boxShadow: '0px 1px 3px rgba(60, 64, 67, 0.3), 0px 4px 8px rgba(60, 64, 67, 0.15)',
          transition: 'box-shadow 0.2s ease-in-out',
        }}
        onMouseEnter={(e) => (e.target.style.boxShadow = '0px 2px 6px rgba(60, 64, 67, 0.4)')}
        onMouseLeave={(e) => (e.target.style.boxShadow = '0px 1px 3px rgba(60, 64, 67, 0.3), 0px 4px 8px rgba(60, 64, 67, 0.15)')}
        onClick={handleClick}
      >
        <img
          src={googleIcon}
          alt="Google Logo"
          style={{
            width: '18px',
            height: '18px',
            marginRight: '8px',
          }}
        />
        Sign in with Google
      </button>
    </div>
  );
};

export default GoogleStyledButton;
