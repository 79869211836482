import React, { useState } from 'react';
import './ContactAdder.css';
import useSessionCheck from "../hooks/useSessionCheck"; // Adjust the relative path based on your project structure


const ContactAdder = () => {
    useSessionCheck(); // Check the session when the component mounts
    const [contacts, setContacts] = useState([{ name: '', phone_number: '' }]);

    const handleInputChange = (index, field, value) => {
        const updatedContacts = [...contacts];
        updatedContacts[index][field] = value;
        setContacts(updatedContacts);
    };

    const addContactRow = () => {
        setContacts([...contacts, { name: '', phone_number: '' }]);
    };

    const sendContactsToEndpoints = async (receivedData) => {
        try {
            // Step 1: Parse the received JSON
            const contacts = receivedData.contacts;
            
            // Extract all contact IDs
            const contactIds = contacts.map(contact => contact.contact_id);
    
            // Step 2: Prepare the payload
            const payload = {
                drive: { "/": contactIds },
                dropbox: { "/": contactIds },
            };
    
            console.log('Payload to send:', payload);
    
            // Step 3: Send the POST request
            const response = await fetch('/permissions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
                credentials: 'include',
            });
    
            // Handle the response
            if (response.ok) {
                alert('Contacts successfully sent to both endpoints!');
            } else {
                console.error('Failed to send contacts:', response.statusText);
                alert('Failed to send contacts.');
            }
        } catch (error) {
            console.error('Error while sending contacts:', error);
            alert('An error occurred while sending contacts.');
        }
    };

    const saveContacts = async () => {
        const payload = {
            add: contacts,
        }

        try {
            const response = await fetch('/contacts', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
                credentials: 'include',
            });

            if (response.ok) {
                alert('Contacts have been saved!');
            } else {
                console.error('Failed to save contacts:', response.statusText);
                alert('Failed to save contacts.');
            }
        } catch (error) {
            console.error('Error saving contacts:', error);
            alert('An error occurred while saving contacts.');
        }

        //get contacts
        try {
            const response = await fetch('/contacts', {
                credentials: 'include',
            });
            if (response.ok) {
                const data = await response.json();
                sendContactsToEndpoints(data);
            } else {
                console.error('Failed to get contacts:', response.statusText);
                alert('Failed to get contacts.');
            }

        } catch (error) {
            console.error('Error getting contacts:', error);
        }
        

    };

    return (
        <div className="contact-adder">
            <h2>Add Contacts</h2>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Phone</th>
                    </tr>
                </thead>
                <tbody>
                    {contacts.map((contact, index) => (
                        <tr key={index}>
                            <td>
                                <input
                                    type="text"
                                    placeholder="Name"
                                    value={contact.name}
                                    onChange={(e) =>
                                        handleInputChange(index, 'name', e.target.value)
                                    }
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    placeholder="Phone"
                                    value={contact.phone_number}
                                    onChange={(e) =>
                                        handleInputChange(index, 'phone_number', e.target.value)
                                    }
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {/* tirar o botao de salvar e deixar salvar sempre que eu apertar enter depois de colocar o telefone */}
            <div className="buttons">
                <button onClick={saveContacts} className="save">
                    Save All
                </button>
                <button onClick={addContactRow} className="add-row">
                    Add Contact
                </button>
            </div>
            <a
        href= "/knownoleadge-bases" // Substitua "/target-page" pela URL da página desejada
        //  quando a pessoa conectar, trocar a cor do botao para ver e escrever connected, sinalizando que o usario ja contectou
       
        // onMouseOver={(e) => (e.target.style.backgroundColor = '#e64a19')}
        // onMouseOut={(e) => (e.target.style.backgroundColor = '#ff5722')}
      >
      </a>
      <button
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          backgroundColor: "#28a745",
          color: "#fff",
          padding: "10px 20px",
          border: "none",
          borderRadius: "8px",
          fontSize: "16px",
          cursor: "pointer",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
        onClick={() => {
            fetch("/index", {
                method: "POST",
                credentials: "include",
              });
            window.location.href = "/dashboard";
        }}        
      >
        Continue
      </button>
        </div>
    );
};

export default ContactAdder;
